
import { Options, Vue } from 'vue-class-component';
import { BaseAuthClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';

@Options({})
export default class Login extends Vue {

    error: string = "";

    created() {
        // BaseAuthClient.completeLoginByDomain({
        //     otp: this.$route.query.otp as string,
        //     username: this.$route.query.username as string
        // })
        // .then(x => {
        //     console.log("entrato nel then, bene");
        //     console.log("token", x.token);
        //     console.log("refreshToken", x.refreshToken);
        //     StorageServices.setLoginToken(x.token);
        //     StorageServices.setLoginRefreshToken(x.refreshToken);

        //     LoginServices.LoginCallback(x.token, x.refreshToken);
        // })
        // .catch(err => {
        //     console.log("err", err);
        //     this.error = err;
        // })
    }

}
